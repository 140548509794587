import React, { useMemo } from 'react';
import { Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';

import { Box, Button } from "@mui/material";

import Login from './Login/Login';
import UserResult from './UserResult/UserResult';
import Tested from './Tested/Tested';
import Users from './Users/Users';

import navIcon from '../../images/icon.png';

import styles from './Admin.module.css';
import { logout } from '../../library/functionsAndData';

function Admin({ role }) {
  const location = useLocation();
  const navigate = useNavigate();

  const onLogout = () => {
    logout('adminRefresh', navigate);
  }

  const renderLinks = useMemo(() => {
    return window.location.href.includes('tested') ||
      window.location.href.includes('users') ||
      window.location.href.includes('userResult') ?
      <Box className={styles.navLinks}>
        <Link to='tested' className={styles.link}>Тестируемые</Link>
        <Link to='users' style={{ display: window.location.href.includes('reader') ? 'none' : 'block' }} className={styles.link}>Пользователи</Link>
        <Button
          onClick={onLogout}
          variant='outlined'
          sx={{ color: 'white', borderColor: 'white', marginLeft: '20px' }}
        >
          Выйти
        </Button>
      </Box>
      :
      <></>
  }, [location])

  return (
    <div style={{ width: '100%', height: 'auto' }}>
      <Box className={styles.appBar}>
        <img className={styles.navIcon} src={navIcon} alt='Ошибка изображения' />
        {renderLinks}
      </Box>

      <Routes>
        <Route path="/" element={<Login role={role} />} />
        <Route path="/tested" element={<Tested />} />
        <Route path="/users" element={<Users />} />
        <Route path='/userResult/*' element={<UserResult />} />
      </Routes>
    </div>
  );
}

export default Admin;