import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import { Box, TextField, Button, Dialog, DialogTitle } from "@mui/material";

import styles from "./LoginPage.module.css";

import { logUser } from "../../../library/functionsAndData";

export default function LoginPage() {
  const [personData, setPersonData] = useState({ email: '', password: '' });
  const [inputErrors, setInputErrors] = useState({ email: false, password: false });
  const [errorMes, setErrorMes] = useState('');
  const [open, setOpen] = useState(false);
  const [testRes, setTestRes] = useState({});
  const navigate = useNavigate();

  const onPersonDataChange = e => {
    setPersonData(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  function validateEmail(email) {
    var pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return pattern.test(email);
  }

  function login() {
    const errObj = {
      email: false,
      password: false
    };

    for (let key in personData) {
      if (key === 'email' && !validateEmail(personData[key])) errObj[key] = true;
      else if (!personData[key]) errObj[key] = true;
    }

    for (let key in personData) {
      if (!personData[key]) {
        setInputErrors(errObj);
        return;
      }
    }

    logUser(personData, navigate, setErrorMes, setOpen, setTestRes);
  }

  const inputs = [
    { value: personData.email, name: 'email', label: 'Email', error: inputErrors.email },
    { value: personData.password, type: 'password', name: 'password', label: 'Пароль', error: inputErrors.password }
  ];


  return (
    <Box className={styles.logPage}>
      <Box className={styles.logBlock}>
        <Box className={styles.logTitle}>Вход</Box>
        {
          inputs.map(item => (
            <Box key={item.label} className={styles.inputs}>
              <Box sx={{ fontSize: '18px' }}>{item.label}</Box>
              <TextField
                value={item.value}
                type={item.type}
                onChange={onPersonDataChange}
                size='small'
                name={item.name}
                label={item.label}
                sx={{
                  width: '70%',
                  height: '38px',
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: item.error ? 'red' : '' },
                  }
                }}
              />
            </Box>
          ))
        }

        <Box className={styles.inputs}>
          <Box></Box>
          <Box className={styles.errorMessage}>{errorMes}</Box>
        </Box>

        <Box className={styles.inputs}>
          <Box></Box>
          <Box className={styles.buttonGroup}>
            <Button onClick={login} variant="contained" className={styles.loginButton}>Вход</Button>
            <Button onClick={() => navigate('/')} variant="outlined" className={styles.cancelButton}>Отмена</Button>
          </Box>
        </Box>
      </Box>

      <Box className={styles.alreadyReg}>
        Ещё не регистрировались? Выполните <Link to='/registration' className={styles.regLink}>Регистрацию</Link>
      </Box>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle className={styles.dialogTitle}>Вы уже проходили тест</DialogTitle>
        {/* <Box>Результат: {testRes.resultName}</Box>
        <Box>Описание: {testRes.resultDescription}</Box> */}
      </Dialog>
    </Box>
  );
}
