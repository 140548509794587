import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import instruction from '../../../images/instruction.png';

import styles from './Instruction.module.css';

export default function Instruction() {
  const navigate = useNavigate();

  const token = window.location.pathname.split('/').at(-1);
  if (token && token !== 'instruction') {
    localStorage.setItem('access', JSON.stringify(token));
  }

  return (
    <Box className={styles.instructionPage}>
      <Box className={styles.description}>
        <Box className={styles.descriptionPart}>
          Представьте, что незаполненная форма – это коридор. Ваша задача раскрасить этот коридор.
          Черная рамка - это начало коридора, белый прямоугольник - это “выход” из него. Коридор разбит
          на 6 участков, каждый из которых символизирует этапы Вашей жизни, путь из прошлого к будущему.
        </Box>
        <Box className={styles.descriptionPart}>
          Первые два участка это самые ранние Ваши воспоминания. Представьте их и быстро, не задумываясь,
          закрасьте эти участки на каждой из его «стен», в соответствии с цветовыми ассоциациями, что у
          вас возникают.
        </Box>
        <Box className={styles.descriptionPart}>
          Следующий участок -  более поздние воспоминания.
        </Box>
        <Box className={styles.descriptionPart}>
          Участок далее - это Вы сегодня.
        </Box>
        <Box className={styles.descriptionPart}>
          Последние два участка у белого «выхода» – это вы завтра.
          Раскрасьте их так как вы себе это «завтра» представляете.
        </Box>
        <Box className={styles.descriptionPart}>
          Чтобы закрасить ячейку, сперва кликните на цвет в
          палитре справа, затем – на ячейку.
        </Box>
      </Box>

      <Box className={styles.imgAndBtn}>
        <img className={styles.img} src={instruction} alt="Ошибка изображения" />
        <Button
          onClick={() => navigate('/testing')}
          endIcon={<ArrowForwardIcon />}
          variant='outlined'
          className={styles.nextBtn}
        >
          Далее
        </Button>
      </Box>
    </Box>
  )
}
