import axios from "axios";
import { Buffer } from 'buffer';

const API_URL = window.location.href.includes('localhost')
                ? 'http://192.168.200.28:7777/api'
                : '/api';

const api = axios.create({
  baseURL: API_URL
});

api.interceptors.request.use(
  (config) => {
    return config
  },
  async (error) => {
    console.log(error)
  }
)

api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      window.location.href = '/';
    }

    throw error.response.data.message;
  }
);

export const regUser = (userData, setShowDialog, setErrMes, setIsLoading) => {
  const data = {...userData, role: 'USER'};

  if (window.location.href.includes('proftest.dev.nntu.ru')) {
    data.isInsider = true;
  }

  api
    .post('/auth/registration', { user: data })
    .then(res => {
      localStorage.setItem('access', JSON.stringify(res.data.accessToken));
      
      setShowDialog(true);
    })
    .catch(e => setErrMes(e))
    .finally(_ => setIsLoading(false))
}

export const logUser = (userData, navigate, setErrorMes, setOpen, setTestRes) => {
  api
    .post('/auth/login', { user: {
      email: userData.email.toLowerCase(),
      password: userData.password,
      role: 'USER'
    }})
    .then(res => {
      if (res.data.isPassed) {
        setOpen(true);
      }
      else {
        localStorage.setItem('access', JSON.stringify(res.data.accessToken));
        localStorage.setItem('refresh', JSON.stringify(res.data.refreshToken));
        navigate('/instruction');
      }
    })
    .catch(e => {
      if (e.toLowerCase().includes('неверный')) {
        setErrorMes('Неверный email или пароль')
      } else {
        setErrorMes(e);
      }
    })
}

export const logAdmin = (userData, navigate, role = 'ADMIN', setErrorMes) => {
  api
    .post('/auth/login', { user: {...userData, role: role} })
    .then(res => {
      localStorage.setItem('adminAccess', JSON.stringify(res.data.accessToken));
      localStorage.setItem('adminRefresh', JSON.stringify(res.data.refreshToken));
      
      navigate('tested');
    })
    .catch(e => {
      if (e.toLowerCase().includes('неверный')) {
        setErrorMes('Неверный email или пароль')
      } else {
        setErrorMes(e);
      }
    })
}

export const getTestedUsers = (setTestedUsers, search, filter, page, size) => {
  api
    .get(`/admin/users/prof?search=${search}&filter=${filter}&page=${page}&size=${size}`, {
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('adminAccess'))
      }
    })
    .then(res => { setTestedUsers(res.data) })
    .catch(e => console.log(e))
}

export const getUsers = (setUsers, search, filter, page, size) => {
  api
    .get(`/admin/users/all?search=${search}&filter=${filter}&page=${page}&size=${size}`, {
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('adminAccess'))
      }
    })
    .then(res => { setUsers(res.data) })
    .catch(e => console.log(e))
}

export const createTest = (setTestData) => {
  const wasPassed = JSON.parse(localStorage.getItem('wasPassed'));

  if (!wasPassed) {
    return new Promise((resolve, reject) => {
      api
        .post('/user/test/', {}, {
          headers: {
            Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('access'))
          }
        })
        .then(res => {  
          setTestData(res.data);
          resolve(res.data.id);
        })
        .catch(e => reject(e))
    })
  }
}

export const uploadTestImage = (testId, imageBlob, isDivision) => {
  return new Promise((resolve, reject) => {
    const file = new File([imageBlob], 'image.png', { type: imageBlob.type });
    const formData = new FormData();
    formData.append(file.name, file);
  
    api
      .post('/user/test/uploadPhoto', formData, {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('access')),
          'Content-Type': 'multipart/form-data',
          tid: testId,
          isDivision: isDivision
        }
      })
      .then(res => { resolve(res.data) })
      .catch(e => {
        console.log(e);
        reject(e);
      })
  })
}

export const getTestResult = (id, setProgressValue) => {
  localStorage.setItem('wasPassed', JSON.stringify(true));
  
  return new Promise((resolve, reject) => {
    let intervalCounter = 0;
    
    const interval = setInterval(async () => {
      intervalCounter++;

      const fetchTestRes = await api
        .get(`/user/test/${id}`, {
          headers: {
            Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('access'))
          }
        })
        .catch(e => e)

      setProgressValue(prev => prev < 90 ? prev + 10 : prev + 5);
      if (intervalCounter === 10) {
        setProgressValue(0);
        reject(`No test ${id} result data`);
        clearInterval(interval);
        return;
      }

      if (fetchTestRes?.status === 200) {
        setProgressValue(100);
        resolve(fetchTestRes.data);
        clearInterval(interval);
        return;
      }
    }, 3000);
  })
}

export const getTestResultAdmin = (id) => {
  return new Promise((resolve, reject) => {
    api
      .get(`admin/users/user/${id}`, {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('adminAccess'))
        }
      })
      .then(res => {resolve(res.data)})
      .catch(e => reject(e))
  })
}

export const getExpandedImage = (url, setImgUrl) => {
  const link = url.split('/')[2];

  api
    .get(`/profile/tests/${link}`, {
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('adminAccess'))
      },
      responseType: 'arraybuffer'
    })
    .then(res => {
      const base64 = Buffer.from(res.data, 'binary').toString('base64');

      setImgUrl("data:;base64," + base64);
    })
    .catch(e => console.log(e));
}

export const deletePerson = (id, role) => {
  return new Promise((resolve, reject) => {
    api
      .delete(`admin/users/delete/${id}`, {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('adminAccess'))
        },
        data: { role }
      })
      .then(res => resolve(res))
      .catch(e => reject(e))
  })
}

export const logout = (type, navigate) => {
  api
    .post('auth/logout', {
      refreshToken: JSON.parse(localStorage.getItem(type))
    })
    .then(_ => {
      navigate('/');
      localStorage.clear();
    })
    .catch(e => console.log(e))
}

export const createUser = (user) => {
  return new Promise((resolve, reject) => {
    api
      .post('admin/users/registration/', { user }, {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('adminAccess'))
        }
      })
      .then(_ => resolve(true))
      .catch(e => reject(e))
  })
}

export const sendResult = () => {
  api
    .get('/user/sendResults', {
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('access'))
      }
    })
    .catch(e => console.log(e))
}