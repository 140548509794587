import React from 'react';

export default function Palette({ palettes, paintBlock, divideBlock, eraseBlock, wasEditClicked }) {
  const onBlockClick = (index, type) => {
    paintBlock(index, type);
    divideBlock(index);
    eraseBlock(index);
  }

  return (
    <div>
      <svg
        width="300"
        height="400"
        style={{
          border: '50px solid black',
          backgroundColor: '#fff',
          cursor: wasEditClicked ? 'pointer' : '',
        }}
      >
        <g>
          {
            palettes.map((item, index) => {
              if (item.editClickedTimes === 0) {
                return <path
                  key={item.id}
                  d={`M${item.d1}, ${item.d2}, ${item.d3}, ${item.d4}`}
                  style={{ fill: item.backgroundColor, stroke: 'black' }}
                  onClick={() => onBlockClick(index)}
                />
              }
              if (item.editClickedTimes === 1) {
                return <g key={item.id}>
                  <path
                    d={`M${item.d1}, ${item.d2}, ${item.d3}`}
                    style={{ fill: item.mainBackgroundColor1, stroke: 'black' }}
                    onClick={() => onBlockClick(index, 'd1')}
                  />
                  <path
                    d={`M${item.d1}, ${item.d3}, ${item.d4}`}
                    style={{ fill: item.mainBackgroundColor2, stroke: 'black' }}
                    onClick={() => onBlockClick(index, 'd2')}
                  />
                </g>
              }

              return <g key={item.id}>
                <path
                  d={`M${item.d1}, ${item.d2}, ${item.d4}`}
                  style={{ fill: item.subBackgroundColor1, stroke: 'black' }}
                  onClick={() => onBlockClick(index, 'd3')}
                />
                <path
                  d={`M${item.d2}, ${item.d3}, ${item.d4}`}
                  style={{ fill: item.subBackgroundColor2, stroke: 'black' }}
                  onClick={() => onBlockClick(index, 'd4')}
                />
              </g>
            })
          }
        </g>
      </svg>
    </div>
  )
}
