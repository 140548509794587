export const paletteArr = [
  {
    id: 0, editClickedTimes: 0,
    d1: '0 0', d2: '100 0', d3: '120 32', d4: '40 32',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 1, editClickedTimes: 0,
    d1: '100 0', d2: '201 0', d3: '199 32', d4: '120 32',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 2, editClickedTimes: 0,
    d1: '200 0', d2: '300 0', d3: '278 32', d4: '199 32',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 3, editClickedTimes: 0,
    d1: '300 0', d2: '300 133', d3: '278 136', d4: '278 32',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 4, editClickedTimes: 0,
    d1: '300 133', d2: '300 265', d3: '278 241', d4: '278 136',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 5, editClickedTimes: 0,
    d1: '300 265', d2: '300 400', d3: '278 346', d4: '278 240',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 6, editClickedTimes: 0,
    d1: '300 400', d2: '201 400', d3: '199 346', d4: '278 346',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 7, editClickedTimes: 0,
    d1: '201 400', d2: '102 400', d3: '120 346', d4: '199 346',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 8, editClickedTimes: 0,
    d1: '102 400', d2: '0 400', d3: '41 346', d4: '120 346',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 9, editClickedTimes: 0,
    d1: '0 400', d2: '0 267', d3: '41 241', d4: '41 346',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 10, editClickedTimes: 0,
    d1: '0 267', d2: '0 133', d3: '41 136', d4: '41 241',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 11, editClickedTimes: 0,
    d1: '0 133', d2: '0 0', d3: '41 32', d4: '41 136',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },

  {
    id: 12, editClickedTimes: 0,
    d1: '41 32', d2: '120 32', d3: '136 58', d4: '75 58',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 13, editClickedTimes: 0,
    d1: '120 32', d2: '199 32', d3: '197 58', d4: '136 58',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 14, editClickedTimes: 0,
    d1: '199 32', d2: '278 32', d3: '258 58', d4: '197 58',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 15, editClickedTimes: 0,
    d1: '278 32', d2: '278 136', d3: '258 139', d4: '258 58',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 16, editClickedTimes: 0,
    d1: '278 136', d2: '278 241', d3: '258 220', d4: '258 139',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 17, editClickedTimes: 0,
    d1: '278 241', d2: '278 346', d3: '258 301', d4: '258 220',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 18, editClickedTimes: 0,
    d1: '278 346', d2: '199 346', d3: '197 301', d4: '258 301',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 19, editClickedTimes: 0,
    d1: '199 346', d2: '120 346', d3: '136 301', d4: '197 301',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 20, editClickedTimes: 0,
    d1: '120 346', d2: '41 346', d3: '75 301', d4: '136 301',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 21, editClickedTimes: 0,
    d1: '41 346', d2: '41 241', d3: '75 220', d4: '75 301',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 22, editClickedTimes: 0,
    d1: '41 241', d2: '41 136', d3: '75 139', d4: '75 220',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 23, editClickedTimes: 0,
    d1: '41 136', d2: '41 32', d3: '75 58', d4: '75 139',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },

  {
    id: 24, editClickedTimes: 0,
    d1: '75 58', d2: '136 58', d3: '149 80', d4: '103 80',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 25, editClickedTimes: 0,
    d1: '136 58', d2: '197 58', d3: '195 80', d4: '149 80',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 26, editClickedTimes: 0,
    d1: '197 58', d2: '258 58', d3: '241 80', d4: '195 80',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 27, editClickedTimes: 0,
    d1: '258 58', d2: '258 139', d3: '241 141', d4: '241 80',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 28, editClickedTimes: 0,
    d1: '258 139', d2: '258 220', d3: '241 203', d4: '241 141',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 29, editClickedTimes: 0,
    d1: '258 220', d2: '258 301', d3: '241 264', d4: '241 203',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 30, editClickedTimes: 0,
    d1: '258 301', d2: '197 301', d3: '195 264', d4: '241 264',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 31, editClickedTimes: 0,
    d1: '197 301', d2: '136 301', d3: '149 264', d4: '195 264',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 32, editClickedTimes: 0,
    d1: '136 301', d2: '75 301', d3: '103 264', d4: '149 264',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 33, editClickedTimes: 0,
    d1: '75 301', d2: '75 220', d3: '103 203', d4: '103 264',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 34, editClickedTimes: 0,
    d1: '75 220', d2: '75 139', d3: '103 141', d4: '103 203',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 35, editClickedTimes: 0,
    d1: '75 139', d2: '75 58', d3: '103 80', d4: '103 141',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },

  {
    id: 36, editClickedTimes: 0,
    d1: '103 80', d2: '149 80', d3: '160 98', d4: '126 98',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 37, editClickedTimes: 0,
    d1: '149 80', d2: '195 80', d3: '194 98', d4: '160 98',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 38, editClickedTimes: 0,
    d1: '195 80', d2: '241 80', d3: '227 98', d4: '194 98',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 39, editClickedTimes: 0,
    d1: '241 80', d2: '241 141', d3: '227 143', d4: '227 98',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 40, editClickedTimes: 0,
    d1: '241 141', d2: '241 203', d3: '227 188', d4: '227 143',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 41, editClickedTimes: 0,
    d1: '241 203', d2: '241 264', d3: '227 233', d4: '227 188',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 42, editClickedTimes: 0,
    d1: '241 264', d2: '195 264', d3: '194 233', d4: '227 233',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 43, editClickedTimes: 0,
    d1: '195 264', d2: '149 264', d3: '160 233', d4: '194 233',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 44, editClickedTimes: 0,
    d1: '149 264', d2: '103 264', d3: '126 233', d4: '160 233',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 45, editClickedTimes: 0,
    d1: '103 264', d2: '103 203', d3: '126 188', d4: '126 233',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 46, editClickedTimes: 0,
    d1: '103 203', d2: '103 141', d3: '126 143', d4: '126 188',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 47, editClickedTimes: 0,
    d1: '103 141', d2: '103 80', d3: '126 98', d4: '126 143',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },

  {
    id: 48, editClickedTimes: 0,
    d1: '126 98', d2: '160 98', d3: '169 114', d4: '146 114',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 49, editClickedTimes: 0,
    d1: '160 98', d2: '194 98', d3: '193 114', d4: '169 114',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 50, editClickedTimes: 0,
    d1: '194 98', d2: '227 98', d3: '216 114', d4: '193 114',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 51, editClickedTimes: 0,
    d1: '227 98', d2: '227 143', d3: '216 145', d4: '216 114',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 52, editClickedTimes: 0,
    d1: '227 143', d2: '227 188', d3: '216 176', d4: '216 145',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 53, editClickedTimes: 0,
    d1: '227 188', d2: '227 233', d3: '216 207', d4: '216 176',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 54, editClickedTimes: 0,
    d1: '227 233', d2: '194 233', d3: '193 207', d4: '216 207',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 55, editClickedTimes: 0,
    d1: '194 233', d2: '160 233', d3: '169 207', d4: '193 207',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 56, editClickedTimes: 0,
    d1: '160 233', d2: '126 233', d3: '146 207', d4: '169 207',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 57, editClickedTimes: 0,
    d1: '126 233', d2: '126 188', d3: '146 176', d4: '146 207',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 58, editClickedTimes: 0,
    d1: '126 188', d2: '126 143', d3: '146 145', d4: '146 176',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 59, editClickedTimes: 0,
    d1: '126 143', d2: '126 98', d3: '146 114', d4: '146 145',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },

  {
    id: 60, editClickedTimes: 0,
    d1: '146 114', d2: '169 114', d3: '177 126', d4: '162 126',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 61, editClickedTimes: 0,
    d1: '169 114', d2: '193 114', d3: '192 126', d4: '177 126',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 62, editClickedTimes: 0,
    d1: '193 114', d2: '216 114', d3: '206 126', d4: '192 126',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 63, editClickedTimes: 0,
    d1: '216 114', d2: '216 145', d3: '206 146', d4: '206 126',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 64, editClickedTimes: 0,
    d1: '216 145', d2: '216 176', d3: '206 165', d4: '206 146',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 65, editClickedTimes: 0,
    d1: '216 176', d2: '216 207', d3: '206 185', d4:'206 165',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 66, editClickedTimes: 0,
    d1: '216 207', d2: '193 207', d3: '192 185', d4: '206 185',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 67, editClickedTimes: 0,
    d1: '193 207', d2: '169 207', d3: '177 185', d4: '192 185',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 68, editClickedTimes: 0,
    d1: '169 207', d2: '146 207', d3: '162 185', d4: '177 185',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 69, editClickedTimes: 0,
    d1: '146 207', d2: '146 176', d3: '162 165', d4: '162 185',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 70, editClickedTimes: 0,
    d1: '146 176', d2: '146 145', d3: '162 146', d4: '162 165',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
  {
    id: 71, editClickedTimes: 0,
    d1: '146 145', d2: '146 114', d3: '162 126', d4: '162 146',
    backgroundColor: '#fff',
    mainBackgroundColor1: '#fff', mainBackgroundColor2: '#fff',
    subBackgroundColor1: '#fff', subBackgroundColor2: '#fff',
  },
];

export const colors1 = [
  '#000', '#5B5B5B', '#898989', '#BFBFBF', '#FFFFFF', '#F70000', '#E75B12',
  '#E1ED00', '#008F39', '#00A0E3', '#0000FF', '#7030A0', '#E100E1'
];

export const colors2 = [
  '#F70000', '#F73737', '#F76E6E', '#F7A5A5', '#F7D2D7', '#C1121C', '#C14953',
  '#C1808A', '#C1969B', '#7E292C', '#9B3337', '#C3474B'
];

export const colors3 = [
  '#E75B12', '#F06E2D', '#F08755', '#F0A573', '#F0C8A0', '#BE4E24', '#BE6437',
  '#BE7850', '#BE8C69', '#EC7C25', '#EC914B', '#ECA55F'
];

export const colors4 = [
  '#FFED00', '#FFF58C', '#FFFAAA', '#FFFFC8', '#F0CA00', '#F0D200', '#F0DC00',
  '#F0E600', '#F0F000', '#F0FA00', '#D9C022', '#E0C83C'
];

export const colors5 = [
  '#008F39', '#00AF46', '#00CD55', '#00EB69', '#0FFF82', '#69FFAF', '#91FFC3',
  '#AAFFD2', '#48A43F', '#55B94B', '#73C369', '#91CD87'
];

export const colors6 = [
  '#00A0E3', '#14AFF0', '#1EB9F5', '#28C8FF', '#37D7FF', '#4BE6E1', '#5FF5E1',
  '#006780', '#0082A0', '#0091AF', '#00A0BE', '#00B4D2'
];

export const colors7 = [
  '#0000FF', '#1414FF', '#2828FF', '#4646FF', '#5A5AFF', '#7373FF', '#9B9BFF',
  '#154889', '#195AAF', '#1E6EC3', '#2382D7', '#2896EB'
];

export const colors8 = [
  '#7030A0', '#873CC3', '#9B5FCD', '#AF82D7', '#C8AAE6', '#48294D', '#693C73',
  '#875096', '#A569B4', '#B987C3', '#A62C6D', '#C33782'
];