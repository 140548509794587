import React from 'react';

import styles from './TestPage.module.css';

export default function AnalizeUserResult({ svgRef, palettes }) {
  return (
    <div className={styles.expand} ref={svgRef}>
      <svg width="580" height="290">
        <g>
          {
            palettes.map((item, index) => {
              const x = index % 12;
              const columnDivider = index % 3 === 0 ? 5 : 0;
              const y = Math.floor(index / 12);

              if (item.editClickedTimes === 0) {
                return <path
                  key={item.id}
                  d={
                    `M${x * 48 + columnDivider} ${y * 48},
                      ${(x + 1) * 48 + columnDivider} ${y * 48},
                      ${(x + 1) * 48 + columnDivider} ${(y + 1) * 48},
                      ${x * 48 + columnDivider} ${(y + 1) * 48}Z`
                  }
                  style={{ fill: item.backgroundColor, stroke: 'black' }}
                />
              }
              if (item.editClickedTimes === 1) {
                return <g key={item.id}>
                  <path
                    style={{ fill: item.mainBackgroundColor1, stroke: 'black' }}
                    d={
                      `M${x * 48 + columnDivider} ${y * 48},
                        ${(x + 1) * 48 + columnDivider} ${y * 48},
                        ${(x + 1) * 48 + columnDivider} ${(y + 1) * 48}Z`
                    }
                  />
                  <path
                    style={{ fill: item.mainBackgroundColor2, stroke: 'black' }}
                    d={
                      `M${x * 48 + columnDivider} ${y * 48},
                        ${(x + 1) * 48 + columnDivider} ${(y + 1) * 48},
                        ${x * 48 + columnDivider} ${(y + 1) * 48}Z`
                    }
                  />
                </g>
              }

              return <g key={item.id}>
                <path
                  style={{ fill: item.subBackgroundColor1, stroke: 'black' }}
                  d={
                    `M${x * 48 + columnDivider} ${y * 48},
                    ${(x + 1) * 48 + columnDivider} ${y * 48},
                    ${x * 48 + columnDivider} ${(y + 1) * 48}Z`
                  }
                />
                <path
                  style={{ fill: item.subBackgroundColor2, stroke: 'black' }}
                  d={
                    `M${(x + 1) * 48 + columnDivider} ${y * 48},
                    ${(x + 1) * 48 + columnDivider} ${(y + 1) * 48},
                    ${x * 48 + columnDivider} ${(y + 1) * 48}Z`
                  }
                />
              </g>
            })
          }
        </g>
      </svg>
    </div>
  )
}
