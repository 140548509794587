import React, { useState, useEffect } from 'react';

import { Box } from '@mui/material';

import ExpandedPhoto from './ExpandedPhoto';

import styles from './UserResult.module.css';

import { getTestResultAdmin } from '../../../library/functionsAndData';

export default function UserResult() {
  const [userData, setUserData] = useState({});
  const userId = window.location.href.split('/').at(-1);

  const dataLabels = [
    { label: 'Имя', name: 'name' },
    { label: 'Возраст', name: 'age' },
    { label: 'Email', name: 'email' },
    { label: 'Насел. пункт', name: 'address' },
  ];


  useEffect(() => {
    getTestResultAdmin(userId).then(res => {
      setUserData(res);
    });
  }, [])

  return (
    <Box className={styles.userResultPage}>
      <Box className={styles.userInfo}>
        {
          dataLabels.map((item) => (
            <Box key={item.name} className={styles.userData}>
              <Box>{item.label}</Box>
              <Box>{userData[item.name]}</Box>
            </Box>
          ))
        }
      </Box>

      {
        userData?.tests?.map((item, index) => (
          <Box key={index} className={styles.imgRes}>
            <Box className={styles.imgTitle}>
              <Box><strong>Дата проведения:</strong> {new Date(Date.parse(item.date)).toLocaleDateString('ru-RU')}</Box>
              <Box><strong>Результат:</strong> {item.result}</Box>
            </Box>
            <ExpandedPhoto url={item?.photo} />
          </Box>
        ))
      }
    </Box>
  )
}
