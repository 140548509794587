import React from "react";
import { Routes, Route } from "react-router-dom";

import { Box } from "@mui/material";

import User from "./components/User/User";
import Admin from "./components/Admin/Admin";

function App() {
  return (
    <Box>
      <Routes>
        <Route path="/*" element={<User />} />
        <Route path="/admin/*" element={<Admin />} />
        <Route path="/reader/*" element={<Admin role='READER' />} />
      </Routes>
    </Box>
  );
}

export default App;
