import React from 'react';
import { Link } from 'react-router-dom';

function NoMatch() {
  console.log("ban")
  return (
    <div
      style={{
        minHeight: '60vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <div style={{ fontSize: '100px', fontWeight: 'bold' }}>404</div>
      <h2>Такой странички нет</h2>
      <h3>
        <Link
          style={{ textDecoration: 'none', color: '#7749F8' }}
          to='/'
        >
          Главная
        </Link>
      </h3>
    </div>
  );
}

export default NoMatch;