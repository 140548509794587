import React, { useState, useEffect } from "react";

import {
  TextField, Box, Paper, Table, TableContainer, TablePagination, InputAdornment, IconButton, Tooltip, Button
} from "@mui/material";

import Tablehead from "./Tablehead/Tablehead";
import Tablebody from "./Tablebody/Tablebody";
import FilterModal from './FilterModal/FilterModal';
import NewUserDialog from "./NewUserDialog/NewUserModal";

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import styles from "./Users.module.css";

import { getUsers, createUser } from '../../../library/functionsAndData';


function Users() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    window.screen.width > 1280
      ? Math.floor(window.screen.height / 120)
      : Math.floor(window.screen.height / 140)
  );
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useState({
    name: '',
    email: '',
    role: ''
  })
  const [filter, setFilter] = useState('');
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openNewUserDialog, setOpenNewUserDialog] = useState(false);
  const [users, setUsers] = useState({ content: [], count: 0 });
  const [wasDeleted, setWasDeleted] = useState(false);

  const onSearchParamsChange = e => {
    setSearchParams(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  const headCells = [
    {
      name: "name",
      label: "Имя",
      searchField: searchParams.name,
      sx: { minWidth: 80 }
    },
    {
      name: "email",
      label: "Login",
      searchField: searchParams.email,
      sx: { minWidth: 120 }
    },
    {
      name: "role",
      label: "Роль",
      searchField: searchParams.role,
      sx: { minWidth: 100 }
    },
    {
      name: "action",
      label:
        <Button onClick={() => setOpenNewUserDialog(true)} className={styles.addNewUserBtn}>
          Добавить
        </Button>,
      sx: { minWidth: 60 }
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onUserCreate = (newUserData) => {
    createUser(newUserData)
      .then(_ => {
        setOpenNewUserDialog(false);
        getUsers(setUsers, search, filter, page, rowsPerPage);
      })
  }

  window.addEventListener('resize', () => {
    setRowsPerPage(
      window.screen.width > 1280
        ? Math.floor(window.screen.height / 120)
        : Math.floor(window.screen.height / 140)
    );

    return () => window.removeEventListener('resize');
  });


  useEffect(() => {
    getUsers(setUsers, search, filter, page, rowsPerPage);
  }, [search, filter, page, rowsPerPage, wasDeleted]);


  const searchAdornment = (
    <InputAdornment position="end">
      <Tooltip title="Поиск по имени и эл. почте">
        <IconButton edge="end">
          <ErrorOutlineOutlinedIcon sx={{ color: '#046bb5' }} />
        </IconButton>
      </Tooltip>
    </InputAdornment>
  );

  return (
    <Box className={styles.users}>
      <Box className={styles.pageTitle}>Пользователи</Box>

      <Box className={styles.searchParams}>
        <TextField
          label="Поиск"
          value={search}
          onChange={e => setSearch(e.target.value)}
          className={styles.tableSearch}
          InputProps={{ endAdornment: searchAdornment }}
        />
        <Button onClick={() => setOpenFilterModal(true)} className={styles.filterButton}>
          <FilterAltOutlinedIcon />
        </Button>
      </Box>

      <Paper className={styles.table} elevation={0}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size="medium">
            <Tablehead
              headCells={headCells}
              onSearchParamsChange={onSearchParamsChange}
            />
            <Tablebody rows={users?.content} setWasDeleted={setWasDeleted} />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[rowsPerPage]}
          component="div"
          count={users.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <FilterModal
        openModal={openFilterModal}
        setOpenModal={setOpenFilterModal}
        filter={filter}
        setFilter={setFilter}
      />

      <NewUserDialog
        open={openNewUserDialog}
        setOpen={setOpenNewUserDialog}
        onUserCreate={onUserCreate}
      />
    </Box>
  );
}

export default Users;
