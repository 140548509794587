import React, { } from 'react';
import { Link } from 'react-router-dom';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import styles from '../Tested.module.css';

export default function Tablebody({ rows }) {
  return (
    <TableBody>
      {rows
        .map((row, index) => {
          return (
            <TableRow hover tabIndex={-1} key={index}>
              <TableCell align="center">
                {
                  row.tests[0]?.date ?
                    new Date(Date.parse(row.tests[0].date)).toLocaleDateString('ru-RU')
                    :
                    ''
                }
              </TableCell>
              <TableCell align="center">
                <Link className={styles.personName} to={`/admin/userResult/${row.id}`}>
                  {`${row.name} ${row.firstname}`}
                </Link>
              </TableCell>
              <TableCell align="center">{row.email}</TableCell>
              <TableCell align="center">{row.age}</TableCell>
              <TableCell align="center">{row.school}</TableCell>
              <TableCell align="center">{row.address}</TableCell>
              <TableCell align="center">{row.tests[0]?.result}</TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  )
}