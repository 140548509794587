import React from 'react';
import { useNavigate } from 'react-router-dom';

// import VkAuth from 'react-vk-auth';

import { Box, Button } from '@mui/material';

import mainBackground from '../../../images/mainBackground.svg';

import styles from './Main.module.css';

export default function Main() {
  const navigate = useNavigate();

  const vkAuth = (data) => {
    console.warn(data);
  }

  return (
    <Box className={styles.greeting}>
      <Box className={styles.testDescription}>
        <Box className={styles.pageTitle}>Кто ты?</Box>

        <Box className={styles.ad}>
          Пройди тестирование и узнай, где тебе интереснее учиться!
        </Box>

        <Box className={styles.description}>
          * - тестирование носит ознакомительный характер, пройти углубленное профессиональное
          тестирование и получить консультацию психолога можно здесь
          <a className={styles.link} target='_blank' href='http://fdp.nntu.ru/proff/'>http://fdp.nntu.ru/proff/</a>
        </Box>

        {/* <VkAuth apiId="51492959" callback={vkAuth} /> */}

        <Button
          onClick={() => navigate('/login')}
          variant='contained'
          className={styles.testButton}
        >
          Пройти тест
        </Button>
      </Box>
      <Box>
        <img className={styles.mainBackground} src={mainBackground} alt="Ошибка изображения" />
      </Box>
    </Box>
  )
}