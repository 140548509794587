import React, { useState, useEffect } from "react";

import {
  TextField, Box, Paper, Button, Table, TableContainer, TablePagination, InputAdornment, IconButton, Tooltip
} from "@mui/material";

import Tablehead from "./Tablehead/Tablehead";
import Tablebody from "./Tablebody/Tablebody";
import FilterModal from "./FilterModal/FilterModal";

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import styles from "./Tested.module.css";

import { getTestedUsers } from "../../../library/functionsAndData";


function Tested() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    window.screen.width > 1280
      ? Math.floor(window.screen.height / 90)
      : Math.floor(window.screen.height / 120)
  );
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useState({
    date: '',
    name: '',
    email: '',
    age: '',
    school: '',
    city: '',
    result: ''
  })
  const [filter, setFilter] = useState('');
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [testedUsers, setTestedUsers] = useState({ content: [], count: 0 });

  window.addEventListener('resize', () => {
    setRowsPerPage(
      window.screen.width > 1280
        ? Math.floor(window.screen.height / 90)
        : Math.floor(window.screen.height / 120)
    );

    return () => window.removeEventListener('resize');
  });

  const onSearchParamsChange = e => {
    setSearchParams(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  useEffect(() => {
    getTestedUsers(setTestedUsers, search, filter, page, rowsPerPage);
  }, [search, filter, page, rowsPerPage])


  const searchAdornment = (
    <InputAdornment position="end">
      <Tooltip title="Поиск по имени, эл. почте и насел. пункту">
        <IconButton edge="end">
          <ErrorOutlineOutlinedIcon sx={{ color: '#7749F8' }} />
        </IconButton>
      </Tooltip>
    </InputAdornment>
  );

  const headCells = [
    {
      name: "date",
      label: "Дата",
      sx: { minWidth: 80 },
      searchField: searchParams.date
    },
    {
      name: "name",
      label: "Имя",
      sx: { minWidth: 120 },
      searchField: searchParams.name
    },
    {
      name: "email",
      label: "Email",
      sx: { minWidth: 100 },
      searchField: searchParams.email
    },
    {
      name: "age",
      label: "Возраст",
      sx: { minWidth: 50 },
      searchField: searchParams.age,
      selectArr: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]
    },
    {
      name: "school",
      label: "Школа",
      sx: { minWidth: 100 },
      searchField: searchParams.school
    },
    {
      name: "city",
      label: "Насел. пункт",
      sx: { minWidth: 100 },
      searchField: searchParams.city
    },
    {
      name: "result",
      label: "Результат",
      sx: { minWidth: 50 },
      searchField: searchParams.result,
      selectArr: ['A', 'B', 'C', 'D', 'E', 'F']
    }
  ];

  return (
    <Box className={styles.tested}>
      <Box className={styles.pageTitle}>Тестируемые</Box>

      <Box className={styles.searchParams}>
        <TextField
          label="Поиск"
          value={search}
          onChange={e => setSearch(e.target.value)}
          className={styles.tableSearch}
          InputProps={{
            endAdornment: searchAdornment,
          }}
        />
        <Button onClick={() => setOpenFilterModal(true)} className={styles.filterButton}>
          <FilterAltOutlinedIcon />
        </Button>
      </Box>

      <Paper className={styles.table} elevation={0}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size="medium">
            <Tablehead
              onSearchParamsChange={onSearchParamsChange}
              headCells={headCells}
            />
            <Tablebody rows={testedUsers.content} />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[rowsPerPage]}
          component="div"
          count={testedUsers.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <FilterModal
        openModal={openFilterModal}
        setOpenModal={setOpenFilterModal}
        filter={filter}
        setFilter={setFilter}
      />
    </Box>
  );
}

export default Tested;
