import React from 'react';

import {
  TextField, TableCell, TableHead, TableRow
} from '@mui/material';

export default function Tablehead({ headCells, onSearchParamsChange }) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.name}
            align='center'
            padding={'normal'}
            sx={headCell.sx}
          >
            {
              headCell.name === 'action' ?
                headCell.label
                :
                <TextField
                  disabled
                  variant='standard'
                  name={headCell.name}
                  value={headCell.searchField}
                  onChange={onSearchParamsChange}
                  label={headCell.label}
                  autoComplete='off'
                />
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}