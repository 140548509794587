import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button } from "@mui/material";

import SettingsIcon from '@mui/icons-material/Settings';

import Palette from './Palette';
import Colors from './Colors';
import Expanded from './Expanded';

import { Canvg } from 'canvg';
import { uploadTestImage, createTest } from '../../../library/functionsAndData';

import styles from './TestPage.module.css';

export default function TestPage({ setTestData, palettes, setPalettes }) {
  const [pickedColor, setPickedColor] = useState('');
  const [wasEditClicked, setWasEditClicked] = useState(false);
  const [wasEraseClicked, setWasEraseClicked] = useState(false);
  const [isDivision, setIsDivision] = useState(false);
  const navigate = useNavigate();
  const svgRef = useRef();
  const wasPassed = JSON.parse(localStorage.getItem('wasPassed'));

  const onAnalizeBtnClick = async () => {
    const svg = svgRef.current.innerHTML;

    const canvas = new OffscreenCanvas(580, 290);
    const ctx = canvas.getContext('2d');
    const v = await Canvg.from(ctx, svg);

    await v.render();

    const blob = await canvas.convertToBlob();

    createTest(setTestData)
      .then(id => uploadTestImage(id, blob, isDivision))
      .then(_ => { navigate('/result') });
  }

  const divideBlock = (index) => {
    if (wasEditClicked) {
      setPalettes(prev => {
        let arr = prev;
        arr[index].editClickedTimes = arr[index].editClickedTimes < 2
          ? arr[index].editClickedTimes + 1
          : 1;

        setIsDivision(true);

        return [...arr];
      });
    }
  }

  const eraseBlock = (index) => {
    if (wasEraseClicked) {
      setPalettes(prev => {
        let arr = prev;

        arr[index].editClickedTimes = 0;
        arr[index].backgroundColor = '#fff';
        arr[index].mainBackgroundColor1 = '#fff';
        arr[index].mainBackgroundColor2 = '#fff';
        arr[index].subBackgroundColor1 = '#fff';
        arr[index].subBackgroundColor2 = '#fff';

        return [...arr];
      })
    }
  }

  const paintBlock = (index, type) => {
    if (!pickedColor) return;

    setPalettes(prev => {
      let arr = prev;
      if (arr[index].editClickedTimes === 0) {
        arr[index].backgroundColor = pickedColor;
        arr[index].mainBackgroundColor1 = '#fff';
        arr[index].mainBackgroundColor2 = '#fff';
        arr[index].subBackgroundColor1 = '#fff';
        arr[index].subBackgroundColor2 = '#fff';
      }
      if (arr[index].editClickedTimes === 1) {
        if (type === 'd1') {
          arr[index].mainBackgroundColor1 = pickedColor;
          arr[index].backgroundColor = '#fff';
          arr[index].subBackgroundColor1 = '#fff';
          arr[index].subBackgroundColor2 = '#fff';
        }
        if (type === 'd2') {
          arr[index].mainBackgroundColor2 = pickedColor;
          arr[index].backgroundColor = '#fff';
          arr[index].subBackgroundColor1 = '#fff';
          arr[index].subBackgroundColor2 = '#fff';
        }
      }
      if (arr[index].editClickedTimes === 2) {
        if (type === 'd3') {
          arr[index].subBackgroundColor1 = pickedColor;
          arr[index].backgroundColor = '#fff';
          arr[index].mainBackgroundColor1 = '#fff';
          arr[index].mainBackgroundColor2 = '#fff';
        }
        if (type === 'd4') {
          arr[index].subBackgroundColor2 = pickedColor;
          arr[index].backgroundColor = '#fff';
          arr[index].mainBackgroundColor1 = '#fff';
          arr[index].mainBackgroundColor2 = '#fff';
        }
      }
      return [...arr];
    })
  }


  // useEffect(() => {
  //   createTest(setTestData);
  // }, [])

  return (
    <Box className={styles.testPage}>
      <Box className={styles.palette}>
        <Palette
          palettes={palettes}
          paintBlock={paintBlock}
          divideBlock={divideBlock}
          eraseBlock={eraseBlock}
          wasEditClicked={wasEditClicked}
        />
        <Colors
          pickedColor={pickedColor}
          setPickedColor={setPickedColor}
          setWasEditClicked={setWasEditClicked}
          setWasEraseClicked={setWasEraseClicked}
        />
      </Box>

      <Expanded svgRef={svgRef} palettes={palettes} />

      <Button
        disabled={wasPassed}
        sx={{ mt: '40px', borderColor: 'black', color: 'black' }}
        startIcon={<SettingsIcon />}
        variant='outlined'
        onClick={onAnalizeBtnClick}
        className={styles.sendButton}
      >
        {wasPassed ? 'Вы уже прошли тест' : 'Анализ результата'}
      </Button>
    </Box>
  )
}
