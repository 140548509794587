import React, { useState, useMemo } from 'react';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import { Box, Button } from "@mui/material";

import Instruction from './Instruction/Instruction';
import LoginPage from './LoginPage/LoginPage';
import Register from './Register/Register';
import TestPage from './Testing/TestPage';
import Main from './Main/Main';
import ResultPage from './ResultPage/ResultPage';
import NoMatch from '../NoMatch/NoMatch';

import navIcon from '../../images/icon.png';

import styles from './User.module.css';

import { paletteArr } from './Testing/data';

import { logout } from '../../library/functionsAndData';

export default function User() {
  const [testData, setTestData] = useState({});
  const [palettes, setPalettes] = useState(paletteArr);
  const [testResult, setTestResult] = useState({});
  const [progressValue, setProgressValue] = useState(10);
  const navigate = useNavigate();
  const location = useLocation();

  const onLogout = () => {
    logout('refresh', navigate);
  }

  const renderLogoutButton = useMemo(() => (
    <Button
      onClick={onLogout}
      variant='outlined'
      sx={{
        color: 'white',
        borderColor: 'white',
        display: window.location.href.includes('instruction') ||
          window.location.href.includes('testing') ||
          window.location.href.includes('result')
          ? 'block' : 'none'
      }}
    >
      Выйти
    </Button>
  ), [location])

  return (
    <Box>
      <Box className={styles.appBar}>
        <img className={styles.navIcon} src={navIcon} alt='Ошибка изображения' />
        {renderLogoutButton}
      </Box>

      <Box className={styles.user}>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/login/*" element={<LoginPage />} />
          <Route path="/registration" element={<Register />} />
          <Route path="/instruction/*" element={<Instruction />} />
          <Route
            path="/testing"
            element={
              <TestPage
                setTestData={setTestData}
                palettes={palettes}
                setPalettes={setPalettes}
              />
            }
          />
          <Route
            path="/result"
            element={
              <ResultPage
                testId={testData.id}
                setPalettes={setPalettes}
                progressValue={progressValue}
                setProgressValue={setProgressValue}
                testResult={testResult}
                setTestResult={setTestResult}
              />
            }
          />
          <Route path="/*" element={<NoMatch />} />
        </Routes>
      </Box>
    </Box>
  )
}
