import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Box, TextField, Button, Dialog, DialogTitle, Divider,
  MenuItem, FormControl, InputLabel, Select, OutlinedInput
} from "@mui/material";

import LoadingButton from '@mui/lab/LoadingButton';

import SendIcon from '@mui/icons-material/Send';

import styles from "./Register.module.css";

import yandexIcon from '../../../images/yandex.svg';
import vkIcon from '../../../images/vk.svg';

import { regUser } from "../../../library/functionsAndData";


export default function Register() {
  const [personData, setPersonData] = useState({
    email: '',
    name: '',
    firstname: '',
    age: '',
    school: '',
    address: 'Нижний Новгород'
  });
  const [inputErrors, setInputErrors] = useState({
    email: false,
    name: false,
    age: false,
    address: false
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errMes, setErrMes] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const navigate = useNavigate();

  const onPersonDataChange = e => {
    setPersonData(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  function validateEmail(email) {
    var pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return pattern.test(email);
  }

  function register() {
    const errObj = {
      email: false,
      name: false,
      age: false,
      city: false
    };

    Object.keys(personData).forEach((key, index) => {
      if (inputs[index].asterick) {
        if (key === 'email' && !validateEmail(personData[key])) {
          errObj[key] = true;
        }
        else if (!personData[key]) errObj[key] = true;
      }
    });

    for (let key in personData) {
      if (errObj[key]) {
        setInputErrors(errObj);
        return;
      }
    }

    setIsLoading(true);

    regUser(personData, setShowDialog, setErrMes, setIsLoading);
  }

  const astercik = <span className={styles.asterick}>*</span>

  const inputs = [
    { value: personData.email, name: 'email', asterick: astercik, label: 'Email', error: inputErrors.email },
    { value: personData.name, name: 'name', asterick: astercik, label: 'Имя', error: inputErrors.name },
    { value: personData.firstname, name: 'firstname', label: 'Фамилия', error: inputErrors.firstname },
    { value: personData.age, name: 'age', asterick: astercik, label: 'Возраст', error: inputErrors.age },
    { value: personData.school, name: 'school', label: 'Школа', error: inputErrors.school },
    { value: personData.address, name: 'address', asterick: astercik, label: 'Населённый пункт', error: inputErrors.address }
  ];

  const ages = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
        width: 250,
      },
    },
  };

  return (
    <Box className={styles.regPage}>
      <Box className={styles.regBlock}>
        <Box className={styles.regTitle}>Регистрация</Box>
        <Box className={styles.regWays}>
          <Box className={styles.regFields}>
            <Box className={styles.title}>Регистрация</Box>

            <Box className={styles.inputFields}>
              {
                inputs.map(item => (
                  <Box key={item.name} className={styles.inputs}>
                    <Box sx={{ fontSize: '18px' }}>
                      {
                        item.asterick
                          ? <div>{item.label}{item.asterick}</div>
                          : item.label
                      }
                    </Box>
                    {
                      item.name === 'age' ?
                        <FormControl size='small' sx={{ width: '50%' }}>
                          <InputLabel>{item.label}</InputLabel>
                          <Select
                            name={item.name}
                            value={item.value}
                            onChange={onPersonDataChange}
                            input={<OutlinedInput label={item.label} />}
                            error={item.error}
                            MenuProps={MenuProps}
                          >
                            {
                              ages.map((item, index) => (
                                <MenuItem key={index} value={item}>{item}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                        :
                        <TextField
                          name={item.name}
                          value={item.value}
                          onChange={onPersonDataChange}
                          size='small'
                          label={item.label}
                          sx={{
                            width: '50%',
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": { borderColor: item.error ? 'red' : '' },
                            }
                          }}
                        />
                    }
                  </Box>
                ))
              }
            </Box>

            <Box className={styles.errorMessage}>{errMes}</Box>

            <Box className={styles.buttonGroup}>
              <LoadingButton
                loading={isLoading}
                loadingPosition="end"
                endIcon={<SendIcon />}
                onClick={register}
                variant="contained"
                className={styles.regButton}
              >
                Зарегистироваться
              </LoadingButton>
            </Box>
          </Box>

          <Divider orientation="vertical" className={styles.divider} flexItem />

          <Box className={styles.logWith}>
            <Box className={styles.title}>Войти через...</Box>

            <Box className={styles.icons}>
              <Button disabled className={styles.yandexButton}>
                <img
                  src={yandexIcon}
                  alt='Ошибка изображения'
                  style={{ width: "50px", height: "50px" }}
                />
                <Box sx={{ width: '150px' }}>Яндекс</Box>
              </Button>
              <Button disabled className={styles.vkButton}>
                <img
                  src={vkIcon}
                  alt='Ошибка изображения'
                  style={{ width: "50px", height: "50px" }}
                />
                <Box sx={{ width: '150px' }}>ВКонтакте</Box>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={styles.alreadyReg}>
        Уже регистрировались? Выполните <Link to='/login' className={styles.loginLink}>Вход</Link>
      </Box>

      <Dialog open={showDialog}>
        <DialogTitle>Регистрация</DialogTitle>
        <Box className={styles.dialogText}>
          На почту <strong>{personData.email}</strong> отправлено письмо для завершения регистрации.
        </Box>
        <Box className={styles.dialogButtons}>
          <Button variant='outlined' onClick={() => navigate(window.location.href.includes('proftest.dev.nntu.ru') ? '/instruction' : '/')}>ОК</Button>
        </Box>
      </Dialog>
    </Box>
  );
}
