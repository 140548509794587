import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, LinearProgress, CircularProgress } from "@mui/material";

import { getTestResult, sendResult } from '../../../library/functionsAndData';

import styles from './ResultPage.module.css';

export default function ResultPage({
  testId, testResult, setTestResult, setPalettes, progressValue, setProgressValue
}) {
  const navigate = useNavigate();
  const wasPassed = JSON.parse(localStorage.getItem('wasPassed'));

  const onSubmit = (url) => {
    setPalettes(prev => {
      return prev.map(item => {
        return {
          ...item,
          backgroundColor: '#fff',
          mainBackgroundColor1: '#fff',
          mainBackgroundColor2: '#fff',
          subBackgroundColor1: '#fff',
          subBackgroundColor2: '#fff',
          editClickedTimes: 0
        }
      })
    });

    localStorage.clear();
    navigate(url);
  }


  useEffect(() => {
    if (!wasPassed) {
      getTestResult(testId, setProgressValue)
        .then(res => {
          setTestResult(res);
          // sendResult();
        })
    }
  }, [])

  return (
    <Box className={styles.resultPage}>
      <LinearProgress
        className={styles.linearProgress}
        variant='determinate'
        color={progressValue > 0 ? 'primary' : 'error'}
        value={progressValue}
      />

      <Box className={styles.resultContent}>
        <Box className={styles.resultData}>
          <Box>Дата прохождения теста:</Box>
          <Box className={styles.boldData}>
            {
              progressValue < 100 && progressValue > 0 ?
                <CircularProgress
                  size={20}
                  variant={progressValue === 0 ? 'determinate' : 'indeterminate'}
                  value={100}
                  color={progressValue > 0 ? 'primary' : 'error'}
                />
                :
                new Date(Date.parse(testResult?.passedDate)).toLocaleDateString('ru-RU')
            }
          </Box>
        </Box>
        <Box className={styles.resultData}>
          <Box>Результат:</Box>
          <Box className={styles.boldData}>
            {
              progressValue < 100 && progressValue > 0 ?
                <CircularProgress
                  size={20}
                  variant={progressValue === 0 ? 'determinate' : 'indeterminate'}
                  value={100}
                  color={progressValue > 0 ? 'primary' : 'error'}
                />
                :
                testResult?.resultName
            }
          </Box>
        </Box>
        <Box className={styles.resDescription}>
          {progressValue < 100 && progressValue > 0 ? '' : testResult?.resultDescription}
        </Box>
      </Box>

      <Box className={styles.btnGroup}>
        <Button variant='outlined' className={styles.submitButton} onClick={() => onSubmit('/')}>
          Выход
        </Button>
      </Box>

    </Box>
  )
}
