import React, { useEffect, useState } from 'react';

import { getExpandedImage } from '../../../library/functionsAndData';

export default function ExpandedPhoto({ url }) {
  const [imgUrl, setImgUrl] = useState('');

  useEffect(() => {
    if (url) getExpandedImage(url.link, setImgUrl);
  }, [])

  return (
    <img src={imgUrl} alt="Ошибка изображения" />
  )
}
