import React, { useState } from 'react';

import { Dialog, DialogTitle, Box, Button, TextField, MenuItem, DialogContent, DialogActions } from '@mui/material';

import styles from '../Users.module.css';

function NewUserDialog({ open, setOpen, onUserCreate }) {
  const [newUserData, setNewUserData] = useState({
    role: '',
    email: '',
    password: '',
    name: '',
    grade: '',
    address: ''
  });

  const onNewUserDataChange = (e) => {
    setNewUserData(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  const inputs = [
    { value: newUserData.email, name: 'email', label: 'Email' },
    { value: newUserData.password, name: 'password', label: 'Пароль' },
    { value: newUserData.name, name: 'name', label: 'Имя' },
    {
      value: newUserData.grade,
      name: 'grade',
      label: 'Класс',
      sx: { display: newUserData.role === 'USER' ? 'block' : 'none' }
    },
    {
      value: newUserData.address,
      name: 'address',
      label: 'Адрес',
      sx: { display: newUserData.role === 'USER' ? 'block' : 'none' }
    },
  ];

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Создание пользователя</DialogTitle>

      <DialogContent className={styles.newUserDialogContent}>
        <TextField
          select
          size='small'
          label='Тип пользователя'
          name='role'
          sx={{ mt: '10px', width: '100%' }}
          value={newUserData.role}
          onChange={onNewUserDataChange}
        >
          <MenuItem value="USER">Пользователь</MenuItem>
          <MenuItem value="READER">Читатель</MenuItem>
          <MenuItem value="ADMIN">Администратор</MenuItem>
        </TextField>

        <Box className={styles.newUserInputs}>
          {
            inputs.map((item, index) => (
              <TextField
                key={index}
                label={item.label}
                className={styles.newUserInput}
                sx={item.sx}
                fullWidth
                size='small'
                name={item.name}
                value={item.value}
                onChange={onNewUserDataChange}
              />
            ))
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} variant='outlined' color='error'>Отмена</Button>
        <Button onClick={() => onUserCreate(newUserData)} variant='outlined'>Создать</Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewUserDialog;