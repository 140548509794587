import React from 'react';

import {
  TextField, MenuItem, FormControl, InputLabel, Select, TableCell, TableHead, TableRow
} from '@mui/material';

import styles from '../Tested.module.css';

export default function Tablehead({ headCells, onSearchParamsChange }) {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
        width: 250,
      },
    },
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.name}
            align='center'
            padding={'normal'}
            sx={headCell.sx}
          >
            {
              headCell?.selectArr ?
                <FormControl disabled variant='standard' size='small' className={styles.headSearch}>
                  <InputLabel>{headCell.label}</InputLabel>
                  <Select
                    name={headCell.name}
                    value={headCell.value}
                    onChange={onSearchParamsChange}
                    MenuProps={MenuProps}
                  >
                    {
                      headCell.selectArr.map((item, index) => (
                        <MenuItem key={index} value={item}>{item}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                :
                <TextField
                  disabled
                  className={styles.headSearch}
                  label={headCell.label}
                  value={headCell.searchField}
                  name={headCell.name}
                  onChange={onSearchParamsChange}
                  size='small'
                  variant='standard'
                  autoComplete='off'
                />
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}