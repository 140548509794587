import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, TextField } from '@mui/material';

import styles from './Login.module.css';

import { logAdmin } from '../../../library/functionsAndData';

function Login({ role }) {
  const [adminData, setAdminData] = useState({ email: '', password: '' })
  const [loginError, setLoginError] = useState({ login: false, password: false });
  const [errorMes, setErrorMes] = useState('');
  const navigate = useNavigate();

  const onAdminDataChange = e => {
    setAdminData(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  const login = () => {
    const errObj = { login: false, password: false }

    for (const key in adminData) {
      if (!adminData[key]) errObj[key] = true;
    }

    for (const key in errObj) {
      if (errObj[key]) {
        setLoginError(errObj);
        return;
      }
    }

    logAdmin(adminData, navigate, role, setErrorMes);
  }

  const onEnterPress = (e) => {
    if (e.key === 'Enter') login();
  }

  const inputs = [
    { value: adminData.email, name: 'email', label: 'Логин', error: loginError.email },
    { value: adminData.password, name: 'password', label: 'Пароль', error: loginError.password },
  ];

  return (
    <Box className={styles.adminLoginPage}>
      <Box className={styles.logBlock}>
        <Box className={styles.title}>
          {
            role ? 'Читатель' : 'Администратор'
          }
        </Box>

        <Box className={styles.inputBlock}>
          {
            inputs.map(item => (
              <TextField
                key={item.name}
                label={item.label}
                value={item.value}
                name={item.name}
                onKeyDown={onEnterPress}
                onChange={onAdminDataChange}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: item.error ? 'red' : '' },
                  }
                }}
                className={styles.input}
              />
            ))
          }
        </Box>

        <Box className={styles.errorMessage}>{ errorMes }</Box>

        <Button onClick={login} variant='contained' className={styles.loginButton}>Войти</Button>
      </Box>
    </Box>
  );
}

export default Login;