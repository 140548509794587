import React, { useState } from 'react';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { Dialog, Box, Button } from '@mui/material';

import styles from '../Users.module.css';

import { deletePerson } from '../../../../library/functionsAndData';

export default function Tablebody({ rows, setWasDeleted }) {
  const [showDialog, setShowDialog] = useState(false);
  const [selected, setSelected] = useState({});

  const onPersonDelete = (person) => {
    setShowDialog(true);
    setSelected(person);
  }

  const submitDelete = () => {
    deletePerson(selected.id, selected.role)
      .then(_ => {
        setWasDeleted(prev => !prev);
        setShowDialog(false);
      })
  }

  return (
    <TableBody>
      {rows
        .map((row, index) => {
          return (
            <TableRow hover tabIndex={-1} key={index}>
              <TableCell align="center" className={styles.personName}>
                {`${row.name} ${row.firstname ?? ''}`}
              </TableCell>
              <TableCell align="center">{row.email}</TableCell>
              <TableCell align="center">
                {row.role === 'USER' ? 'Пользователь' : row.role === 'ADMIN' ? 'Администратор' : 'Читатель'}
              </TableCell>
              <TableCell align="center">
                <Button color='error' onClick={() => onPersonDelete(row)}>
                  Удалить
                </Button>
              </TableCell>
            </TableRow>
          );
        })}

      <Dialog
        onClose={() => setShowDialog(false)} open={showDialog}
        PaperProps={{
          style: { borderRadius: '15px' }
        }}
      >
        <Box className={styles.deleteDialogTitle}>Подтвердите удаление</Box>
        <Box className={styles.deleteDialogBtns}>
          <Button
            size="small"
            variant='outlined'
            color='error'
            onClick={() => setShowDialog(false)}
          >
            Отмена
          </Button>
          <Button
            size="small"
            variant='outlined'
            color='primary'
            onClick={submitDelete}
          >
            ОК
          </Button>
        </Box>
      </Dialog>
    </TableBody>
  )
}