import React, { useState } from 'react';

import { Box, TextField, Button, MenuItem, Paper, Modal, Fade, Backdrop } from '@mui/material';

import styles from '../Users.module.css';

function FilterModal({ openModal, setOpenModal, filter, setFilter }) {
  const [tmpFilter, setTmpFilter] = useState(filter ? filter : 'noFilter');

  const saveFilters = () => {
    setFilter(prev => {
      if (tmpFilter === 'noFilter') return '';
      return tmpFilter;
    });
    setOpenModal(false);
  }

  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Paper className={styles.filterModal}>
          <h1>Фильтры</h1>
          <TextField
            select
            required
            label="Роль"
            value={tmpFilter}
            size="small"
            sx={{ width: '100%' }}
            onChange={e => setTmpFilter(e.target.value)}
          >
            {[
              { value: "noFilter", label: "Без фильтра" },
              { value: "ADMIN", label: "Администратор" },
              { value: "USER", label: "Тестируемый" },
              { value: "READER", label: "Читатель" },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <Box className={styles.buttons}>
            <Button className={styles.modalFilterButton} onClick={saveFilters}>
              Применить
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  )
}

export default FilterModal;