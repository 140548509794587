import React, { useState } from 'react';

import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import eraserIcon from '../../../images/eraser.svg';

import styles from './TestPage.module.css';

import {
  colors1, colors2, colors3, colors4,
  colors5, colors6, colors7, colors8
} from './data';

export default function Colors({ pickedColor, setPickedColor, setWasEditClicked, setWasEraseClicked }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const onMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const onColorPick = (item) => {
    setPickedColor(prev => {
      if (prev !== item) return item;
      return '';
    });
    setWasEditClicked(false);
    setWasEraseClicked(false);
  }

  const onEditClick = () => {
    setWasEditClicked(true);
    setWasEraseClicked(false);
    setPickedColor('');
    setAnchorEl(null);
  }

  const onEraserClick = () => {
    setWasEraseClicked(true);
    setWasEditClicked(false);
    setPickedColor('#fff');
    setAnchorEl(null);
  }

  return (
    <Box className={styles.colors}>
      {
        [colors1, colors2, colors3, colors4, colors5, colors6, colors7, colors8].map((array, index) => (
          <Box key={index}>
            {
              array.map((item, index) => (
                <Box
                  key={index}
                  sx={{ backgroundColor: item, borderColor: item === pickedColor ? 'blue' : 'black' }}
                  className={styles.singleColor}
                  onClick={() => onColorPick(item)}
                >
                </Box>
              ))
            }
          </Box>
        ))
      }

      <Box className={styles.otherButtons}>
        <IconButton onClick={onMenuClick}>
          <MenuOutlinedIcon sx={{ color: '#bbb' }} />
        </IconButton>

        <Menu anchorEl={anchorEl} open={open} onClose={onMenuClose}>
          <Tooltip placement='right' title='Разделить ячейку'>
            <MenuItem onClick={onEditClick}>
              <EditOutlinedIcon sx={{ color: '#000' }} />
            </MenuItem>
          </Tooltip>
          <Tooltip placement='right' title='Стереть'>
            <MenuItem onClick={onEraserClick}>
              <img src={eraserIcon} />
            </MenuItem>
          </Tooltip>
        </Menu>
      </Box>
    </Box>
  )
}
